import { render, staticRenderFns } from "./advertisingReview.vue?vue&type=template&id=5b5dc875&scoped=true&"
import script from "./advertisingReview.vue?vue&type=script&lang=js&"
export * from "./advertisingReview.vue?vue&type=script&lang=js&"
import style0 from "./advertisingReview.vue?vue&type=style&index=0&id=5b5dc875&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b5dc875",
  null
  
)

export default component.exports