<template>
    <div>
        <el-main>
            <!-- 表单 -->
            <el-form class="el-form-search" label-width="110px">
                <el-form-item label="订单号：">
                    <el-input size="small" placeholder="请输入订单号" v-model="searchForm.order_no"></el-input>
                </el-form-item>
                <el-form-item label="是否付费：">
                    <el-select v-model="searchForm.is_pay" size="small" placeholder="请选择" clearable>
                        <el-option label="全部" :value="0"></el-option>
                        <el-option label="否" :value="-1"></el-option>
                        <el-option label="是" :value="1"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="申请时间：">
                    <el-date-picker size="small" v-model="searchForm.start_time" type="daterange" range-separator="~"
                        start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
                </el-form-item>
                <el-form-item label="店铺名称：">
                    <el-input size="small" placeholder="请输入店铺名称" v-model="searchForm.store_name"></el-input>
                </el-form-item>
                <el-form-item label="商家姓名：">
                    <el-input size="small" placeholder="请输入商家姓名" v-model="searchForm.merchant_name"></el-input>
                </el-form-item>
                <el-form-item label="商家电话：">
                    <el-input size="small" type="number" placeholder="请输入商家电话"
                        v-model="searchForm.merchant_mobile"></el-input>
                </el-form-item>
                <el-form-item label=" " label-width="25px">
                    <el-button type="primary" size="small" @click="search">搜索</el-button>
                    <el-button type="text" size="small" @click="clearSearch">清空搜索条件</el-button>
                </el-form-item>
            </el-form>
            <el-tabs v-model="searchForm.status" type="card" @tab-click="handleClick(searchForm.status)">
                <el-tab-pane :name="item.status" v-for="(item, index) in AdOrderStatus" :key="index">
                    <span slot="label">{{ item.title }}（{{ item.count }}）</span>
                </el-tab-pane>
            </el-tabs>
            <!-- 列表 -->
            <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }" :key="key">
                <template slot="empty">
                    <No />
                </template>
                <el-table-column prop="order_no" label="订单号" width="200" align="center"></el-table-column>
                <el-table-column prop="u_mobile" label="用户手机" width="110" align="center"></el-table-column>
                <el-table-column prop="store_name" label="店铺名称" width="120" align="center"></el-table-column>
                <el-table-column prop="merchant_name" label="商家姓名" width="100" align="center"></el-table-column>
                <el-table-column prop="merchant_mobile" label="商家电话" width="110" align="center"></el-table-column>
                <el-table-column prop="city_name" label="上架城市" width="120" align="center"></el-table-column>
                <el-table-column prop="type" label="广告类型" width="120" align="center">
                    <template v-slot="{ row }">
                        <span>
                            {{ row.type == 1 ? '图片' : '视频' }}
                        </span>
                        <el-button v-if="row.type == 2" @click="handleVideo(row.ad_video)" type="text"
                            size="small">查看</el-button>
                    </template>
                </el-table-column>
                <el-table-column prop="ad_picture" label="广告图片" align="center">
                    <template v-slot="{ row }">
                        <div class="demo-image__preview">
                            <el-image style="width: 100px; height: 50px" :src="row.ad_picture[0]"
                                :preview-src-list="row.ad_picture"></el-image>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="type" label="VR链接" width="260" align="center">
                    <template v-slot="{ row }">
                        <el-link type="primary" v-if="row.type == 1" :href="row.vr_url" target="_blank">{{ row.vr_url
                        }}</el-link>
                        <span v-else>视频</span>
                    </template>
                </el-table-column>
                <el-table-column prop="day" label="申请天数" width="100" align="center"></el-table-column>
                <el-table-column prop="is_pay" label="是否付费" width="100" align="center">
                    <template v-slot="{ row }">
                        <span>
                            {{ row.is_pay == -1 ? '否' : '是' }}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column prop="pay_type" label="支付方式" width="100" align="center">
                    <template v-slot="{ row }">
                        <span>
                            {{ (row.is_pay == 1 && row.status > -1) ? (row.pay_type == 1 ? '微信' : row.pay_type == 2 ? '支付宝'
                                : '--') : '--' }}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column prop="amount" label="广告费用" width="100" align="center">
                    <template v-slot="{ row }">
                        <span>
                            ￥{{ row.amount }}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column prop="status" label="广告状态" width="100" align="center">
                    <template v-slot="{ row }">
                        <span>
                            {{ row.status == -1 ? '待支付' : row.status == 1 ? '审核中' : row.status == 2 ? '已通过' : row.status ==
                                3 ? '已驳回' : row.status == 4 ? '已取消' : '--' }}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column prop="create_time" label="申请时间" width="200" align="center">
                    <template v-slot="{ row }">{{ getDateformat(row.create_time) }}</template>
                </el-table-column>
                <el-table-column label="操作" width="150" align="center" fixed="right">
                    <template v-slot="{ row }">
                        <div class="btn" v-if="row.status == 1">
                            <el-button @click="review(row, 2)" type="text" size="small">通过</el-button>
                            <el-button @click="review(row, 3)" type="text" size="small">驳回</el-button>
                        </div>
                        <el-button v-if="row.status == 3" @click="(remark = row.remark), (showRemark = !0)" type="text"
                            size="small">驳回原因</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 翻页 -->
            <Paging :total="total_number" :page="searchForm.page" :pageNum="searchForm.rows" @updatePageNum="updateData">
            </Paging>
        </el-main>
        <el-dialog title="审核" :visible.sync="showReview" width="600px">
            <el-form label-width="140px">
                <el-form-item label="审核结果：" required="true">
                    <el-radio-group v-model="status">
                        <el-radio :label="2">通过</el-radio>
                        <el-radio :label="3">驳回</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="上架时间：" v-if="status == 2" required="true">
                    <el-date-picker v-model="show_time" type="datetime" placeholder="选择上架时间" value-format="timestamp"
                        align="right" :picker-options="pickerOptions">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="驳回原因：" v-if="status == 3" required="true">
                    <el-input type="textarea" :rows="4" v-model="remark"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showReview = !1">取 消</el-button>
                <el-button type="primary" @click="comfirmReview">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog title="驳回原因" :visible.sync="showRemark" width="600px">
            {{ remark }}
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="showRemark = !1">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog title="视频" :visible.sync="video_show" width="600px">
            <video :src="video" style="width:400px;height:300px;" controls></video>
        </el-dialog>
    </div>
</template>
<script>

import _ from 'lodash';
import Paging from '@/components/paging';
import { getDateformat } from '@/util/getDate';

export default {
    components: {
        Paging,
    },
    data () {
        return {
            AdOrderStatus: [],
            list: [],
            total_number: 0,
            searchForm: {
                page: 1,
                rows: 10,
                order_no: '',
                is_pay: 0,
                start_time: '',
                end_time: '',
                store_name: '',
                merchant_name: '',
                merchant_mobile: '',
                status: 0,
            },
            showReview: !1,
            showRemark: !1,
            id: '',
            status: 2,
            show_time: '',
            remark: '',
            pickerOptions: {
                disabledDate (val) {
                    return val.getTime() < new Date().getTime() - 86400000;
                },
            },
            video: '',
            video_show: false
        };
    },
    created () {
        this.getList();
        this.getAdOrderStatus();
    },
    methods: {
        getAdOrderStatus () {
            let searchForm = this.dealSearchData();
            this.$axios.post(this.$api.repair.AdOrder.getStatus, searchForm)
                .then(res => {
                    if (res.code == 0) {
                        this.AdOrderStatus = res.result;
                    }
                });
        },
        handleClick () {
            this.searchForm.page = 1;
            this.searchForm.rows = 10;
            this.getList();
        },
        search () {
            this.page = 1;
            this.getList();
            this.getAdOrderStatus();
        },
        clearSearch () {
            this.searchForm = {
                page: 1,
                rows: 10,
                u_mobile: '',
                subsidiary_name: '',
                partner_name: '',
                mobile: '',
                status: 0,
                start_time: '',
                end_time: '',
            };
            this.getList();
            this.getAdOrderStatus();
        },
        getDateformat (val) {
            return getDateformat(val);
        },
        updateData (val, status) {
            if (status == 0) {
                this.searchForm.rows = val;
                this.getList();

            } else {
                this.searchForm.page = val;
                this.getList();

            }
        },
        dealSearchData () {
            let searchForm = _.cloneDeep(this.searchForm);
            if (searchForm.start_time) {
                searchForm.end_time = searchForm.start_time[1] / 1000;
                searchForm.start_time = searchForm.start_time[0] / 1000;
            }
            return searchForm;
        },
        getList () {
            let searchForm = this.dealSearchData();
            this.$axios.post(this.$api.repair.AdOrder.list, searchForm).then(res => {
                if (res.code == 0) {
                    let list = res.result.list;
                    for (let i in list) {
                        list[i].ad_picture = [list[i].ad_picture];
                    }
                    this.list = res.result.list;
                    this.total_number = res.result.total_number;
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        review (row, status) {
            this.status = status;
            this.id = row.id;
            this.show_time = '';
            this.remark = '';
            this.showReview = !0;
        },
        comfirmReview () {
            if (this.status == 2) {
                if (!this.show_time) {
                    return this.$message.warning('请选择上架时间');
                }
                this.show_time = this.show_time / 1000;
            }
            if (!this.remark && this.status == 3) return this.$message.warning('请添加驳回原因');
            this.$axios.post(this.$api.repair.AdOrder.audit, {
                id: this.id,
                status: this.status,
                show_time: this.show_time,
                remark: this.remark,
            })
                .then(res => {
                    if (res.code == 0) {
                        this.showReview = !1;
                        this.$message.success('处理成功');
                        this.getList();
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        },
        handleVideo (ad_video) {
            this.video = ad_video
            this.video_show = true
        }
    },

};
</script>
<style lang="less" scoped>
.el-main {
    background: #fff;
}
</style>
  